<template>
    <div class="patient-dashboard-view">
        <card title="Patient Dashboard">
            <b-row align-v="end">
                <b-col>
                    <SearchComponent v-model="patient"
                                     :label="`Search`"
                                     :search-u-r-l="`/Patient`"></SearchComponent>
                </b-col>
                <b-col cols="1">
                    <b-button class="my-0" variant="danger" @click="clearClick" block>Clear</b-button>
                </b-col>
            </b-row>
        </card>
        <b-row>
            <b-col>
                <b-alert v-model="showPatientAlert"
                         variant="danger"
                         dismissible>
                    {{patientAlertText}}
                </b-alert>
            </b-col>
        </b-row>

        <b-row v-if="patient.id">
            <b-col cols="4">
                <card>
                    <b-row align-v="center">
                        <b-col cols="8">
                            <h4 class="card-subtitle">{{patientName}}</h4>
                            <b-alert variant="danger">Danger Alert</b-alert>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button variant="info" size="sm" @click="sendNpcText">Send New Script Text</b-button>
                            <b-button variant="info" size="sm" @click="sendRefillText">Send Refill Text</b-button>
                            <b-button variant="info" size="sm" v-b-modal="'demographics-form'">Update Demographics</b-button>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <label><strong>DOB: </strong></label>
                            {{ patient.dateOfBirth | formatDate('MM-DD-YYYY', false) }}
                        </b-col>
                        <b-col>
                            <label><strong>Age: </strong></label>
                            {{ patient.age }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label><strong>Sex: </strong></label>
                            {{patient.sex}}
                        </b-col>
                        <b-col>
                            <label><strong>Height: </strong></label>
                            {{patient.height}}
                        </b-col>
                        <b-col>
                            <label><strong>Weight: </strong></label>
                            {{patient.weight}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label><strong>Email Address: </strong></label>
                            {{patient.emailAddress}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label><strong>Phone: </strong></label>
                            {{patient.phoneNumber}}
                            <a href="" v-if="!isMD2RX"><i class="fas fa-history" @click.prevent="openTextMessageModal('phoneNumber')"></i></a>
                        </b-col>
                        <b-col>
                            <label><strong>Cell: </strong></label>
                            {{patient.cellNumber}}
                            <a href="" v-if="!isMD2RX"><i class="fas fa-history" @click.prevent="openTextMessageModal('cellNumber')"></i></a>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <label><strong>Address1: </strong></label>
                            {{patient.address1}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label><strong>Address2: </strong></label>
                            {{patient.address2}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label><strong>City: </strong></label>
                            {{ patient.addressCity }}
                        </b-col>
                        <b-col>
                            <label><strong>State: </strong></label>
                            {{ patient.addressState }}
                        </b-col>
                        <b-col>
                            <label><strong>Zip: </strong></label>
                            {{ patient.addressZip }}
                        </b-col>
                    </b-row>
                    <b-row v-if="patient && patient.programs">
                        <b-col>
                            <label><strong>Programs:</strong></label>
                            {{ patient.programs.map(pp => pp.programName).join(', ') }}
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <checkbox inline disabled v-model="pregnant">Pregnant</checkbox>
                            <checkbox inline disabled v-model="lactating">Lactating</checkbox>
                            <checkbox inline disabled v-model="smoker">Smoker</checkbox>
                            <checkbox inline disabled v-model="patient.childCaps">Use Safety Caps</checkbox>
                        </b-col>
                    </b-row>
                </card>
                <card v-if="!isMD2RX">
                    <!-- Notes -->
                    <h4 class="card-subtitle">Notes</h4>
                    <NotesList ref="patientNotesList"
                               :entityId="patient.id"
                               :entityType="patientEntityType"
                               :pageSize="5"></NotesList>
                </card>
                <card>
                    <h4 class="card-subtitle">Shipments</h4>
                    <!-- Shipments -->
                    <div v-if="patientShipments.length > 0">
                        <card v-for="shipment in patientShipments" :key="shipment.id">
                            <b>{{'Shipped: '}}</b> {{convertDate(shipment.verified)}}<br />
                            <b>{{'Shipment Type: '}}</b> {{shipment.shipmentType}}<br />
                            <b>{{'Tracking: '}}</b><b-link target="_blank" :href="shipment.trackingUrl">{{shipment.trackingNumber}}</b-link><br>
                            <a href="" @click.prevent="showShippingTicket(shipment)">Shipping Ticket</a>
                        </card>
                    </div>
                    <div v-else>
                        No Shipments On Record
                    </div>

                </card>

                <card>
                    <b-row>
                        <b-col>
                            <!-- Insurance Plans -->
                            <h5>Insurance Plans</h5>
                            <b-button variant="primary" v-b-modal="'insurance-update-form'" size="sm">Request Update</b-button>
                            <PatientPlansComponent style="overflow-x:scroll" v-model="patient"></PatientPlansComponent>
                        </b-col>
                    </b-row>
                </card>
                <card>
                    <h4 class="card-subtitle">Allergies</h4>
                    <b-row>
                        <b-col>
                            <!-- ALLERGIES -->
                            <ul v-if="allergies && allergies.length>0">
                                <li v-for="a in allergies" :key="a.id">
                                    {{(a.allergyName + ' - ') + (a.allergyType == 0 ? 'Class' : 'Ingredient')}}
                                </li>
                            </ul>
                            <div v-else>
                                No known drug allergies
                            </div>
                        </b-col>
                    </b-row>
                </card>
            </b-col>

            <b-col cols="8">
                <!-- ORDERS -->
                <card>
                    <b-row>
                        <b-col><h4 class="card-subtitle">Orders</h4></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <SearchComponent v-model="filterStore"
                                             :label="`Pharmacy`"
                                             :search-u-r-l="`/Store`"
                                             clearable
                                             prepopulate
                                             @input="loadOrders(patient.id)"></SearchComponent>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <checkbox inline v-model="showCancelled" @change="loadOrders(patient.id)">Show Cancelled</checkbox>
                            <checkbox inline v-model="showShipped" @change="loadOrders(patient.id)">Show Shipped</checkbox>
                        </b-col>
                    </b-row>

                    <OrdersListComponent :orders="orders" expandable></OrdersListComponent>

                </card>
                <!-- AUTOREFILL SUBSCRIPTIONS-->
                <card>
                    <h4 class="card-subtitle">Autorefill Subscriptions</h4>
                    <RXSubscriptionsGrid ref="rxSubscriptionsGrid"
                        v-model="patient.id"
                    ></RXSubscriptionsGrid>
                </card>
                <!-- PRESCRIPTIONS -->
                <card>
                    <h4 class="card-subtitle">Scripts</h4>

                    <PrescriptionsListComponent :prescriptions="prescriptions"
                                                :actionButtons="prescriptionListActionButtons"
                                                colorCodePrescriptions
                                                showReportProblemButton
                                                showProccessQueueButton
                                                refillBatchMode
                                                @batchFinished="loadPrescriptions"></PrescriptionsListComponent>
                </card>
            </b-col>

        </b-row>

        <!-- Shipping Ticket Modal -->
        <b-modal id="shippingTicketModal" hide-footer
                 ref="shippingTicketModal"
                 title="Shipping Ticket" size="xl">
                 <ShippingTicketComponent
                    v-model="shipmentToViewInTicket"
                 ></ShippingTicketComponent>
        </b-modal>

        <b-modal id="insurance-update-form" ref="insurance-update-form"
            title="Insurance Update Request" size="xl" hide-footer
        >
            <InsuranceUpdateRequestFormComponent
                :patient-id="patient.id"
            ></InsuranceUpdateRequestFormComponent>
        </b-modal>

        <b-modal id="demographics-form" ref="demographics-form"
            title="Patient Demographics" hide-footer
        >
            <PatientDemographicsFormComponent
                :patient-id="patient.id"
                @saved="closeAndRefreshPatientDemographics"
            ></PatientDemographicsFormComponent>
        </b-modal>

        <TextMessagesList ref="textMessagesListModal" :patient="patient"></TextMessagesList>

    </div>
</template>
<script lang="ts">
    import Checkbox from "@/components/Inputs/Checkbox.vue";
    import SearchComponent from '@/components/SearchComponent.vue';

    import PrescriptionsListComponent from '@/components/Prescription/PrescriptionsListComponent.vue';
    import OrdersListComponent from '@/components/Order/OrdersListComponent.vue';
    import ShippingTicketComponent from '@/components/Shipping/ShippingTicketComponent.vue';
    import InsuranceUpdateRequestFormComponent from '@/components/Insurance/InsuranceUpdateRequestFormComponent.vue';
    import NotesList from '@/components/Note/NotesList.vue';
    import TextMessagesList from '@/components/TextMessage/TextMessagesList.vue';
    import NotesForm from '@/components/Note/NotesForm.vue';
    import {Patient } from '@/models/Patient';
    import CaseDetailComponent from '@/components/Cases/CaseDetailComponent.vue';
    import {DeliveryCode, Prescription } from '@/models/Prescription';
    import RxTransferModalComponent from '@/components/RxTransfer/RxTransferModalComponent.vue';
    import {CaseDetail} from '@/models/Case';
    import {Store} from '@/models/Store';
    import { EntityType} from '@/models/Note';
    import {Order} from '@/models/Order';
    import {LockHandler, Lock} from '@/mixins/LockHandler';
    import axios, {AxiosError} from 'axios';
    import {DatePicker, Table, TableColumn} from "element-ui";
    import {Component, Watch, Mixins, Ref} from 'vue-property-decorator';
    import Card from "@/components/Cards/Card.vue";
    import PriorAuthorizationListComponent from "@/components/PriorAuthorization/PriorAuthorizationListComponent.vue";
    import moment from "moment";
    import {GSDDPatientAllergy} from '../../models/GSDD';
    import PatientPlansComponent from "@/components/Insurance/PatientPlansComponent.vue";
    import IRSReportComponent from "@/components/EmbeddedReports/IRSReportComponent.vue";
    import NewConsultationFormComponent from '@/components/Consultation/NewConsultationFormComponent.vue';
    import ConsultationListComponent from "@/components/Consultation/ConsultationListComponent.vue";
    import {Shipment} from "@/models/Shipment";
    import PatientDemographicsFormComponent from '@/components/Patient/PatientDemographicsFormComponent.vue';
    import { NotificationOptions } from "@/util/NotificationOptionsPresets";
    import RXSubscriptionsGrid from '@/components/Prescription/RXSubscriptionsGrid.vue'

    enum searchType {
        Unknown = 1,
        Empty = 2,
        String = 3,
        Phone = 4,
        Rx = 5
    }

    @Component({
        components: {
            SearchComponent,
            Checkbox,
            PrescriptionsListComponent,
            OrdersListComponent,
            NotesList, NotesForm,
            Card,
            [DatePicker.name]: DatePicker,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            ConsultationListComponent,
            PriorAuthorizationListComponent,
            CaseDetailComponent,
            PatientPlansComponent,
            RxTransferModalComponent,
            IRSReportComponent,
            NewConsultationFormComponent,
            ShippingTicketComponent,
            TextMessagesList,
            InsuranceUpdateRequestFormComponent,
            PatientDemographicsFormComponent,
            RXSubscriptionsGrid
        }
    })
    export default class PatientDashboardPage extends Mixins(LockHandler) {
        private patient: Patient = new Patient();
        private patients: Patient[] = [];
        private prescriptions: Prescription[] = [];
        private orders: Order[] = [];
        private prescriptionsDataTable: any[] = [];
        private prescriptionSelected: any | Prescription = {};
        private allergies: GSDDPatientAllergy[] = [];
        private caseSelected: CaseDetail | any = {};
        private displayName = "";
        private searchValue = "";
        private showActiveTransferAlert = false;
        private selectedRxTransfer: number | null = null;
        private showPatientAlert = false;
        private showCancelled = false;
        private showShipped = false;
        private patientShipments: Shipment[] = [];
        private numberType:string = "";

        private patientSearchType: searchType = searchType.Unknown;
        private patientLock: Lock | null = null;
        private deliveryCodes = DeliveryCode;

        private filterStore: Store = new Store();
        private patientEntityType = EntityType.Patient;

        private buttonTitles = {
            new: "New Patient",
            clear: "Clear",
            save: "Save Changes",
        };

        private prescriptionListActionButtons: Array<string> = [];

        created() {
            this.prescriptionListActionButtons.push('edit');
            // do some sort of traffic logging here for ease of bug reporting in future
            // If route as a PatientId as argument, load its data by default.
            if (this.routePatientId) {
                axios.get(`/Patient/${this.routePatientId}`)
                    .then(response => {
                        this.patient = Object.assign(new Patient(), response.data);
                    })
                    .catch(error => {
                        console.error("Problem loading patient information by route Id", {error, response: error?.response});
                    });
            }
        }

        get isMD2RX():boolean{
            return this.$userProgram == 'MD2RX';
        }

        @Watch('$route.params.id') onParamChanged(value: string, oldValue: string) {
            if (value != oldValue) {
                this.patient = Object.assign(new Patient(), {id: value});
            }
        }
        @Watch('patient') onPatientChanged(value: Patient | null, oldValue: Patient | null) {
            if (!value) {
                this.displayName = "";
                return;
            }
            const patient: Patient = value as Patient;
            if (!patient.id) {
                patient.updateNamesForPersonFromDisplayName(this.displayName);
            } else {

                if (!oldValue || oldValue.id != patient.id) {
                    if(this.$route?.params.id != patient.id.toString()) {
                        this.$router.push({name: 'PatientDashboard', params: {id: patient.id.toString()}});
                    }

                    this.clearData();
                    // Get the full patient object instead of the tiny stub we get from backend.
                    axios.get(`/Patient/${patient.id}`)
                        .then(response => {
                            this.patient = Object.assign(new Patient(), response.data);
                            this.displayName = patient.id ? patient.displayNamesForPerson() : '';
                            if (!this.routePatientId)
                                this.$router.push({name: 'PatientDashboard', params: {id: patient.id.toString()}});
                        })
                        .catch(error => {
                            console.error("Problem loading patient information its selection on the search component", {error, response: error?.response});
                        });

                    this.loadPrescriptions();
                    this.loadOrders(patient.id);

                    //Get patient's allergies
                    axios.get(`Patient/GetGSDDAllergiesForPatient/${patient.id}`)
                        .then(response => {
                            this.allergies = response.data;
                        })
                        .catch(error => {
                            console.error("Problem loading allergies", {error, response: error?.response});
                        });

                    axios.get(`/Patient/GetShipmentsByPatient/${patient.id}`)
                        .then(response => {
                            this.patientShipments = response.data;
                        }).catch(error => {
                            console.error("Error Getting Shipments", {error, response: error?.response});
                        });

                }
            }
        }

        clearData() {
            this.prescriptions = [];
            this.orders = [];
            this.allergies = [];
        }

        clearClick() {
            this.clearData();
            this.patient = new Patient();
        }

        loadPrescriptions() {
            if (!this.patient?.id) return;

            //Get patient's prescriptions and format the data to be shown in the grid.
            axios.get(`/Patient/${this.patient.id}/prescriptions`)
                .then(response => {
                    this.prescriptions = response.data.map((rx: Prescription) => Object.assign(new Prescription(), rx));
                    this.loadRxsAvailableForAutorefill();
                })
                .catch(error => {
                    console.error("Problem loading prescriptions", {error, response: error?.response});
                });
        }
        loadRxsAvailableForAutorefill(){
            this.$http.get<Array<Prescription>>(`autorefill/available-for-autorefill/${this.patient.id}`)
            .then(res => {
                if(res.data?.length){
                    const availableRxsForAutorefill = res.data.map(rx => Object.assign(new Prescription(),rx));
                    this.prescriptions.forEach(rx => {
                        rx.isAvailableForAutorefill = availableRxsForAutorefill.some(rx2 => rx2.rxID == rx.rxID);
                    })
                }
            })
            .catch(err => {
                console.error("Error loading Rxs available for subscription to auto refill.", {err, response: err?.response})
            })
        }

        loadAutoRefillData(){
            this.loadRxsAvailableForAutorefill();
            let rxSubscriptionsGrid = (this.$refs.rxSubscriptionsGrid as RXSubscriptionsGrid);
            if(rxSubscriptionsGrid) rxSubscriptionsGrid.loadData();
        }

        loadOrders(patientId?: string | number) {
            patientId = patientId || this.patient.id;
            let storeId = this.filterStore?.id == 0 || this.filterStore?.id ? this.filterStore?.id : '';
            //Get patient's prescriptions and format the data to be shown in the grid.
            axios.get(`/Patient/${patientId}/orders/${this.showCancelled}/${this.showShipped}/${storeId}`)
                .then(response => {
                    this.orders = response.data;
                    this.orders.sort((a: Order, b: Order) => {
                        return new Date(b.created).getTime() - new Date(a.created).getTime();
                    });
                })
                .catch(error => {
                    console.error("Problem loading patient orders", {error, response: error?.response});
                });
        }

        get patientAlertText(): string {
            if (!this.patient || !this.patient.alert) return "";

            return `Patient Memo: ${this.patient.alert}`;
        }

        @Watch('patient.alert') onAlertChange(value: string, oldValue: string) {
            this.showPatientAlert = (!!value && !oldValue);
        }

        @Watch('searchValue') searchValueChange(value: string, oldValue: string) {
            this.updatePatientSearchType();
        }

        get routePatientId(): number {
            let res = 0;
            res = parseInt(this.$route.params.id);
            if (isNaN(res))
                return 0;
            return res;
        }

        get patientName(): string {
            let res = '';
            if (this.patient)
                res = this.patient.displayNamesForPerson();
            return res;
        }

        get deliveryName(): string {
            let res = '';
            if (this.patient)
                res = DeliveryCode[this.patient.defaultDelivery];
            return res;
        }

        formatDate(d: Date): string {
            if (!d) return "";
            return moment.utc(d).local().format("L");
        }

        // pregnant
        get pregnant(): any {
            return (this.patient?.pregnant) ? true : false;
        }

        // lactating
        get lactating(): any {
            return (this.patient?.lactating) ? true : false;
        }

        // smoker
        get smoker(): any {
            return (this.patient?.smoker) ? true : false;
        }

        updatePatientSearchType() {
            let type = searchType.Unknown;
            if (!this.searchValue.length) {
                type = searchType.Empty;
            } else if (this.searchValue.charAt(0) == "+") {
                type = searchType.Phone;
            } else if (isNaN(Number(this.searchValue))) {
                type = searchType.String;
            } else {
                type = searchType.Rx;
            }
            this.patientSearchType = type;
        }

        refreshNotesList() {
            if (this.$refs.patientNotesList instanceof NotesList)
                (this.$refs.patientNotesList as any).loadNotes();
        }

        convertDate(date: string) {
            return moment.utc(date).format("L");
        }

        selectCase(item: CaseDetail) {
            this.caseSelected = item;
        }

        private shipmentToViewInTicket:Shipment = new Shipment();
        showShippingTicket(shipment:Shipment){
            this.shipmentToViewInTicket = shipment;
            this.$bvModal.show("shippingTicketModal");
        }

        openTextMessageModal(numberType:string){
            this.numberType = numberType;
            var component = this.$refs.textMessagesListModal as TextMessagesList;
            if(component) component.openModal(this.numberType);
        }

        sendNpcText(){
            axios.post('patient/sendNpcText', this.patient).then(textResp=>{
                console.log("textResp: ", textResp);
            })
        }
        sendRefillText() {
            axios.post('patient/sendRefillText', this.patient).then(textResp => {
                console.log("textResp: ", textResp);
                this.$notification(NotificationOptions.notificationOptionsPreset("Refill Text Sent."
                        , NotificationOptions.NotificationTypes.success))
            })
        }

        closeAndRefreshPatientDemographics(pat:Patient){
            this.$bvModal.hide('demographics-form');
            this.patient.address1 = pat.address1;
            this.patient.address2 = pat.address2;
            this.patient.addressCity = pat.addressCity;
            this.patient.addressState = pat.addressState;
            this.patient.addressZip = pat.addressZip;
            this.patient.cellNumber = pat.cellNumber;
            this.patient.phoneNumber = pat.phoneNumber;
        }
    }
</script>

<style scoped>
    .form-check > .form-check-label {
        color: black;
    }
</style>
