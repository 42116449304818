<template>
    <Card title="Doctor Dashboard">
        <b-row>
            <b-col cols="6">
                <b-form-group label="Search By Doctor:">
                    <b-input v-model.lazy="searchTerm"
                             placeholder="NPI/Name/PhoneNumber"
                             @blur="updateSearchValue"
                             @keyup.enter="updateSearchValue"></b-input>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="From Date">
                    <el-date-picker v-model="fromDate" format="MM/dd/yyyy" type="date"></el-date-picker>
                </b-form-group>
            </b-col>
            <b-col>

                <b-form-group label="To Date">
                    <el-date-picker v-model="toDate" format="MM/dd/yyyy" type="date"></el-date-picker>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table id="scripts-by-doctor"
                         ref="scriptTable"
                         :fields="fields"
                         :filter="tableFilter"
                         :items="ctxProvider"
                         api-url="/Prescription/ByPrescriber"
                         :tbody-tr-class="colorCodeTable"
                         no-provider-sorting
                         show-empty
                         sort-icon-left>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template #cell(edit)="data">
                        <router-link :to="rxDetailsRoute(data.item)">
                            <b-icon v-b-tooltip.hover icon="pencil-square" title="Edit"></b-icon>
                        </router-link>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </Card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { BvTableCtxObject } from "bootstrap-vue";
import { Prescription, RefillStatusCode } from "@/models/Prescription";
import moment from "moment";
import { Patient } from "@/models/Patient";
import { Prescriber } from "@/models/Prescriber";
import { DatePicker } from "element-ui";

@Component({
    name: "DoctorDashboard",
    components: {
        [DatePicker.name]: DatePicker,
    },
})
export default class DoctorDashboard extends Vue {
    private searchTerm = "%";
    private searchValue = "%";
    private toDate: any = moment().toDate();
    private fromDate: any = moment().subtract(7, "days").toDate();
    private cancelTokenSource?: CancelTokenSource;
    private fields = [
        { key: "productNameShort", label: "Drug", sortable: true },
        { key: "prescriber", formatter: this.prescriberFormatter, sortable: true, },
        { key: "patient", label: "Patient", formatter: this.patientFormatter, sortable: true, class: "patient-col" },
        { key: "storeName", label: "Store", sortable: true },
        { key: "rxId", sortable: true },
        { key: "quantity", sortable: true },
        { key: "writtenDate", formatter: (d: any) => moment(d).format("L"), sortable: true },
        { key: "fillDate", formatter: (d: any) => moment(d).format("L"), sortable: true, },
        { key: "refillDue", },
        { key: "patientPay", formatter: (d: number) => `$${d?.toFixed(2) ?? '0.00'}`, sortable: true },
        { key: "programName", label: "Program", sortable: true },
        { key: "state", },
        { key: "orderStatus", label: "Order Status", sortable: true, },
        { key: "trackingNumber", label: "Tracking#", sortable: true, },
        { key: "shipDate", label: "Ship Date", formatter: (d: any) => d.length > 0 ? moment(d).format("L") : "", },
        { key: "edit", label: "" },
    ];

    get tableFilter() {
        return {
            searchTerm: this.searchValue,
            fromDate: this.fromDate,
            toDate: this.toDate,
        };
    }

    prescriberFormatter(dr: Prescriber): string {
        dr = new Prescriber(dr);
        return dr.displayNamesForPerson();
    }

    patientFormatter(pt: Patient): string {
        pt = Object.assign(new Patient(), pt);
        return pt.displayNamesForPerson();
    }

    updateSearchValue() {
        this.searchValue = this.searchTerm
    }

    private calculateRefillDue = (item: any) => {
        // (days supply - 21) for first fill, (days supply - 14) on subsequent fills
        console.log("item: ", item);
        let daysToAdd = item.daySupply - 14;
        if (item.rfNumber == 0) daysToAdd -= 7;

        let day = moment.utc(item.dateOfService).add(daysToAdd, "days");
        return day.format('L');
    };

    ctxProvider(ctx: BvTableCtxObject, cb: Function) {
        if (ctx.apiUrl == null) return null;

        if (typeof this.cancelTokenSource != typeof undefined) {
            this.cancelTokenSource?.cancel("Operation canceled due to new request.");
        }
        this.cancelTokenSource = Axios.CancelToken.source();

        const uri = ctx.apiUrl;
        const config: AxiosRequestConfig = {
            params: ctx.filter,
            cancelToken: this.cancelTokenSource.token
        };

        Axios.get<Prescription[]>(uri, config)
            .then(resp =>
                cb(resp.data.map(rx => {
                    const item = new Prescription(rx) as any;
                    item.refillDue = this.calculateRefillDue(rx);
                    return item;
                })))
            .catch(err => {
                console.warn(err);
                cb([])
            })
            .finally(() => {
                this.cancelTokenSource = undefined;
            });
        return null;
    }

    rxDetailsRoute(item: Prescription): any {
        return {
            name: "PrescriptionDetails",
            params: {
                storeID: item.storeID,
                rxNumber: item.rxNumber,
                rfNumber: item.rfNumber
            }
        };
    }

    colorCodeTable(rxRow: Prescription, type: any) {
        console.log("userProgram: ", this.$userProgram);
        if (!rxRow || type !== 'row') return;
        const rx = new Prescription(rxRow);
        if (rx.status == RefillStatusCode.TransferOut)
            return 'table-info';
        else if (rx.status == RefillStatusCode.Void)
            return 'table-dark';
        else if (!rx.isRefillableStatus)
            return 'table-danger';
    }
}

</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
::v-deep .patient-col {
    max-width: 20rem;
}
</style>
