@include form-control-placeholder($medium-gray, 1);

.form-control {
  background-color: $transparent-bg;
  border: 1px solid $light-gray;
  border-radius: $btn-round-radius;
  color: $black-color;
  line-height: normal;
  height: auto;
  font-size: $font-size-small;
  @include transition-input-focus-color();
  @include box-shadow(none);



  &:focus{
    border: 1px solid $primary-color;
    @include box-shadow(none);
    outline: 0 !important;
    color: $black-color;

    & + .input-group-addon,
    & ~ .input-group-addon{
      border: 1px solid $primary-color;
      border-left: none;
      background-color: $transparent-bg;
    }
  }

  .has-success &,
  .has-error &,
  .has-success &:focus,
  .has-error &:focus{
    @include box-shadow(none);
  }

  .has-danger &,
  .has-success &{
    &.form-control-success,
    &.form-control-danger{
      background-image: none;
    }
  }

  & + .form-control-feedback{
    border-radius: $border-radius-large;
    font-size: $font-size-base;
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
    vertical-align: middle;
  }

  .open &{
    border-radius: $border-radius-large $border-radius-large 0 0;
    border-bottom-color: transparent;
  }

  & + .input-group-addon{
    background-color: $white-bg;
  }
}

.has-success .input-group-addon,
.has-success .form-control{
  border-color: $light-gray;
}

.has-success .form-control:focus,
.has-success.input-group-focus .input-group-addon{
  border-color: lighten($success-color, 5%);
}

.has-danger .form-control,
.has-danger .input-group-addon,
.has-danger.input-group-focus .input-group-addon{
  //border-color: lighten($danger-color, 30%);
  border-color: $danger-color;
  color: $danger-color;
  background-color: rgba(222,222,222, .1);
  font-weight: bold;

  &:focus{
    background-color: $white-color;
  }
}

.has-success,
.has-danger{
  &:after{
    font-family: 'Nucleo Outline';
    content: "\ea22";
    display: inline-block;
    position: absolute;
    right: 20px;
    bottom: 10px;
    top: 10px;
    color: $success-color;
    font-size: 11px;
  }

  &.form-control-lg{
    &:after{
      font-size: 13px;
      top: 24px;
    }
  }

  &.has-label{
    &:after{
      top: 35px;
    }
  }

  .form-control + label,
  &.form-check .form-check-label label,
  &.form-check:after{
    display: none !important;
  }

  &.form-check .form-check-label{
    color: $success-color;
  }
}

.has-danger{
  &:after{
    content: "\ea53";
    color: $danger-color;
  }

  &.form-check .form-check-label{
    color: $danger-color;
  }
}

@include form-control-lg-padding($padding-large-vertical, $padding-input-horizontal);
@include input-base-padding($padding-input-vertical, $padding-input-horizontal);

.form-group.no-border,
.input-group.no-border{
  .form-control,
  .form-control + .input-group-addon{
    background-color: $opacity-gray-3;
    border: medium none;
    &:focus,
    &:active,
    &:active{
      border: medium none;
      background-color: $opacity-gray-5;
    }
  }

  .form-control{
    &:focus{
      & + .input-group-addon{
        background-color: $opacity-gray-5;
      }
    }
  }

  .input-group-addon{
    background-color: $opacity-gray-3;
    border: none;
  }
}

.has-error{
  .form-control-feedback, .control-label{
    color: $danger-color;
  }
}
.has-success{
  .form-control-feedback, .control-label{
    color: $success-color;
  }
}

.input-group-addon {
  background-color: $white-bg;
  border: 1px solid $light-gray;
  color: $dark-background;
  padding: $padding-base-vertical - 1 0 $padding-base-vertical - 1 $padding-base-horizontal - 1;

  &.input-group-append {
    border-radius: 0 $btn-round-radius $btn-round-radius 0;
    margin-left: 0;
  }
  &.input-group-prepend {
    margin-right: 0;
    border-radius: $btn-round-radius 0 0 $btn-round-radius;
  }
  @include transition-input-focus-color();

  .has-danger.input-group-focus &{
    background-color: $white-color;
  }

  .has-success &{
    background-color: $white-color;
  }
  .has-danger .form-control:focus + &{
    color: $danger-color;
  }
  .has-success .form-control:focus + &{
    color: $success-color;
  }

  & + .form-control,
  & ~ .form-control{
    @include input-size($padding-base-vertical - 1, $padding-base-horizontal);
    padding-left: 18px;
  }

  i{
    width: 17px;
  }
}

.input-group-focus{
  .input-group-addon{
    background-color: $white-bg;
    border-color: $primary-color;
  }

  &.no-border{
    .input-group-addon{
      background-color: $opacity-gray-5;
    }
  }
}

.input-group,
.form-group{
  margin-bottom: 10px;
  position: relative;

  .form-control-static{
    margin-top: 9px;
  }
}
.input-group[disabled]{
  .input-group-addon{
    background-color: $light-gray;
  }
}

.input-group .form-control:not(:first-child):not(:last-child), .input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child){
  border-radius: $btn-round-radius;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0 none;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-left: 0 none;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: $light-gray;
  color: $smoke-bg;
  cursor: not-allowed;
}

.input-group-btn .btn{
  border-width: $border-thin;
  padding: $padding-btn-vertical  $padding-base-horizontal;
}
.input-group-btn .btn-default:not(.btn-fill){
  border-color: $medium-gray;
}

.input-group-btn:last-child > .btn{
  margin-left: 0;
}
textarea.form-control {
    max-width: 100%;
    max-height: 80px;
    padding: 10px;
    resize: none;
    /*border: none;*/
    border: 1px solid $light-gray;
    border-radius: 10px;
    line-height: 2;

    &:focus,
    &:active {
        /*
    border-left: none;
    border-top: none;
    border-right: none;
          */
    }
}

.has-success,
.has-danger{

  &.form-group .form-control,
  &.form-group.no-border .form-control{
    padding-right: $padding-input-horizontal + 21;
  }
}

.form.form-newsletter .form-group{
  float: left;
  width: 78%;
  margin-right: 2%;
  margin-top: 9px;
}

.input-group .input-group-btn{
  padding: 0 12px;
}

// Input files - hide actual input - requires specific markup in the sample.
.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.form-text{
  font-size: $font-size-small;
}

.form-control-lg{
  padding: 0;
  font-size: inherit;
  line-height: 0;
  border-radius: 0;
  &.has-danger {
    margin-bottom: 30px;
  }
}

.form-horizontal{
  .col-form-label,
  .label-on-right{
    padding: 10px 5px 0 15px;
    text-align: right;
    max-width: 180px;
  }

  .checkbox-radios{
    margin-bottom: 15px;

    .form-check:first-child{
      margin-top: 8px;
    }
  }

  .label-on-right{
    text-align: left;
    padding: 10px 15px 0 5px;
  }

  .form-check-inline{
    margin-top: 6px;
  }
}
