<template>
    <b-row :style="showButtonOnly ? 'display:inline-block' : ''">
        <b-col>
            <b-table v-if="plans && plans.length > 0" v-show="!showButtonOnly"
                     :fields="planFields"
                     :items="insuranceInfos">
            </b-table>
            <div v-else v-show="!showButtonOnly">
                No registed insurance plans for this patient.
            </div>
        </b-col>
    </b-row>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
    import { Patient } from '@/models/Patient';
    import { Plan } from '@/models/Plan';
    import { PatientPlan, RelationshipCode } from '@/models/PatientPlan';
    import Axios from 'axios';
    import PlanDetailsPage from '@/pages/Plan/PlanDetailsPage.vue';
    import SearchComponent from '@/components/SearchComponent.vue';
    import EnumSelector from '@/components/EnumSelector.vue';
    import { NotificationOptions } from '@/util/NotificationOptionsPresets';
    import { CombinedPlan } from "@/models/CombinedPlan";
    import { isNullOrUndefined } from "util";

    @Component({
        components: {
            PlanDetailsPage,
            SearchComponent,
            EnumSelector,
        }
    })
    export default class PatientPlansComponent extends Vue {
        @VModel({type: Patient, default: new Patient()}) private patient!: Patient;
        @Prop({ default: false }) private showButtonOnly!: boolean;
        @Prop({ default: false }) private disabled!: boolean;
        @Prop({ default: null }) private addTemplate!: CombinedPlan;
        private showNewPlan = false;
        private prePopulatePlanString: any = "";

        mounted() {
            this.fetchPlans();
        }

        clear() {
            this.addedPatientPlan = new PatientPlan();
            this.addedPlan = new Plan();
            this.$emit('hide');
        }

        private insuranceInfos: CombinedPlan[] = [];
        private plans: Plan[] = [];
        private addedPlan: Plan = new Plan();
        private addedPatientPlan: PatientPlan = new PatientPlan();
        private relationshipCodes = RelationshipCode;
        private planFields: any[] = [
            { key: "plan.name", label: "Name", },
            { key: "plan.bin", label: "BIN", },
            { key: "plan.pcn", label: "PCN", },
            { key: "patientPlan.memberId", label: "Member Id", },
            { key: "patientPlan.groupId", label: "Group Id", },
            { key: "patientPlan.cardholderId", label: "Cardholder Id", },
            { key: "patientPlan.relationshipCode", label: "Relationship Code", }
        ];
        private planButtonTitles = {
            new: "",
            clear: "",
            save: "Save Changes",
            cancel: ""
        };

        @Watch('patient')
        fetchPlans() {
            if (!this.patient.id) return;

            Axios.get(`patient/insurance/${this.patient.id}`)
                .then(response => {
                    this.plans = response.data.plans;
                    this.insuranceInfos = response.data.plans.map((item: any, i: number) => {
                        return { plan: item, patientPlan: response.data.patientPlans[i] };
                    });
                })
                .catch(error => {
                    console.error("Error while loading the insurance plans", { error, response: error?.response });
                });
        }

        get isValid(): boolean {
            return !!(this.patient?.id);
        }
    }
</script>

<style scoped>
    .card {
        overflow: auto;
    }
</style>
