<template>
    <Card title="Drug Dashboard">
        <b-row align-v="end">
            <b-col cols="6">
                <SearchComponent v-model="drug" label="Drug" searchURL="/Drug"></SearchComponent>
            </b-col>
            <b-col>
                <b-form-group label="From Date">
                    <el-date-picker v-model="fromDate" type="date" format="MM/dd/yyyy"></el-date-picker>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="To Date">
                    <el-date-picker v-model="toDate" type="date" format="MM/dd/yyyy"></el-date-picker>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table id="scripts-by-drug"
                         ref="scriptTable"
                         api-url="/Prescription/ByDrug"
                         :items="ctxProvider"
                         :fields="fields"
                         :filter="tableFilter"
                         :tbody-transition-props="{ name: 'flip-list' }"
                         :tbody-tr-class="colorCodeTable"
                         sort-icon-left
                         no-provider-sorting
                         show-empty>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template #cell(rxId)="data">
                        <span v-if="isRefillable(data.item)">
                            {{ data.item.rxId }}
                        </span>
                        <b v-else v-b-tooltip.hover :title="statusName(data.item)" style="cursor: pointer">
                            {{ data.item.rxId }}
                        </b>
                    </template>
                    <template #cell(edit)="data">
                        <router-link :to="rxDetailsRoute(data.item)">
                            <b-icon icon="pencil-square" v-b-tooltip.hover title="Edit"></b-icon>
                        </router-link>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </Card>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { DatePicker } from "element-ui";
import moment from "moment";
import DisableAutocompleteMixin from "@/mixins/DisableAutocompleteMixin";
import { Patient } from "@/models/Patient";
import { Prescriber } from "@/models/Prescriber";
import { BvTableCtxObject } from "bootstrap-vue";
import Axios, { AxiosRequestConfig } from "axios";
import { Prescription, RefillStatusCode } from '@/models/Prescription';
import SearchComponent from "@/components/SearchComponent.vue";
import { Drug } from "@/models/Drug/Drug";

@Component({
    name: "DrugDashboard",
    components: {
        [DatePicker.name]: DatePicker,
        SearchComponent,
    }
})
export default class DrugDashboard extends Mixins(DisableAutocompleteMixin) {
    private drug: Drug = new Drug();

    private toDate: any = moment().toDate();
    private fromDate: any = moment().subtract(7, "days").toDate();

    private fields = [
        { key: "productNameShort", label: "Drug", sortable: true },
        { key: "prescriber", formatter: this.prescriberFormatter, sortable: true, },
        { key: "patient", label: "Patient", formatter: this.patientFormatter, sortable: true, class: "patient-col" },
        { key: "storeName", label: "Store", sortable: true },
        { key: "rxId", sortable: true },
        { key: "quantity", sortable: true },
        { key: "writtenDate", formatter: (d: any) => moment(d).format("L"), sortable: true },
        { key: "fillDate", formatter: (d: any) => moment(d).format("L"), sortable: true, },
        { key: "refillDue", },
        { key: "patientPay", formatter: (d: number) => `$${d?.toFixed(2) ?? '0.00'}`, sortable: true },
        { key: "programName", label: "Program", sortable: true },
        { key: "state", },
        { key: "orderStatus", label: "Order Status", sortable: true, },
        { key: "trackingNumber", label: "Tracking#", sortable: true, },
        { key: "shipDate", label: "Ship Date", formatter: (d: any) => d.length > 0 ? moment(d).format("L") : "", },
        { key: "edit", label: "" },
    ];

    prescriberFormatter(dr: Prescriber): string {
        dr = new Prescriber(dr);
        return dr.displayNamesForPerson();
    }

    patientFormatter(pt: Patient): string {
        pt = new Patient(pt);
        return pt.displayNamesForPerson();
    }

    get tableFilter() {
        return {
            searchTerm: this.drug.drugId,
            fromDate: this.fromDate,
            toDate: this.toDate,
        };
    }

    private calculateRefillDue = (item: any) => {
        // (days supply - 21) for first fill, (days supply - 14) on subsequent fills
        console.log("item: ", item);
        let daysToAdd = item.daySupply - 14;
        if (item.rfNumber == 0) daysToAdd -= 7;

        let day = moment.utc(item.dateOfService).add(daysToAdd, "days");
        return day.format('L');
    };

    ctxProvider(ctx: BvTableCtxObject, callback: Function) {
        if (ctx.apiUrl == null) return null;

        const uri = ctx.apiUrl;
        const config: AxiosRequestConfig = {
            params: ctx.filter
        };
        Axios.get<Prescription[]>(uri, config)
            .then(resp => {
                callback(resp.data.map(rx => {
                    const item = new Prescription(rx) as any;
                    item.refillDue = this.calculateRefillDue(rx);
                    return item;
                }));
            }).catch(_err => {
            callback([]);
        });
        return null;
    }

    rxDetailsRoute(item: Prescription): any {
        return {
            name: "PrescriptionDetails",
            params: {
                storeID: item.storeID,
                rxNumber: item.rxNumber,
                rfNumber: item.rfNumber
            }
        };
    }

    colorCodeTable(rxRow: Prescription, type: any) {
        if (!rxRow || type !== 'row') return;
        const rx = new Prescription(rxRow);
        if (rx.status == RefillStatusCode.TransferOut)
            return 'table-info';
        else if (rx.status == RefillStatusCode.Void)
            return 'table-dark';
        else if (!rx.isRefillableStatus)
            return 'table-danger';
    }

    isRefillable(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.isRefillableStatus;
    }

    statusName(rxRow: Prescription) {
        const rx = Object.assign(new Prescription(), rxRow);
        return rx.statusName;
    }
}
</script>

<style scoped>
::v-deep table#scripts-by-drug .flip-list-move {
    transition: transform 1s;
}

::v-deep .patient-col {
    max-width: 20rem;
}
</style>
